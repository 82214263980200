import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../../css/skill-swap.css';
import image from '../../assets/vrbo-hero-image.png';
import UsabilityResearch from '../../components/UsabilityResearch';
import Research from '../../components/Research';
import Content from '../../components/Content';
import VRBOImages from '../../components/VRBOImages';

const projectDetails = [
    { title: 'ROLE', content: 'UX Researcher and Tester, UI Designer' },
    { title: 'TOOLS USED', content: 'Figma, MS Teams, Excel' },
    { title: 'SKILLS', content: 'User Interview, Usability Test, Interface Design' },
    { title: 'CLIENT', content: 'VRBO' },
    { title: 'TIMELINE', content: 'Sept 2023 - Dec 2023' },
    { title: 'TEAM MEMBERS', content: '3' }
  ];
  
  const ProjectInfo = () => (
    <>
      <Row className="pb-4">
        {projectDetails.slice(0, 3).map((detail, index) => (
          <Col key={index}>
            <p className="my-0" style={{ color: "gray", fontWeight: "400" }}>{detail.title}</p>
            <p style={{ fontSize: "20px", lineHeight: "1.6em" }}>{detail.content}</p>
          </Col>
        ))}
      </Row>
      <Row>
        {projectDetails.slice(3).map((detail, index) => (
          <Col key={index}>
            <p className="my-0" style={{ color: "gray", fontWeight: "400" }}>{detail.title}</p>
            <p style={{ fontSize: "20px", lineHeight: "1.6em" }}>{detail.content}</p>
          </Col>
        ))}
      </Row>
    </>
  );

const SkillSwap = () => { 
    return (
    <Container fluid className="mx-0 px-0">
      <div className="px-3 backgroundcolor">
        <Row className="align-items-center justify-content-center" style={{ minHeight: "75vh"}}>
          <Col md={8} className="text-center">
            <h1 className="responsive-heading-case-study" style={{ fontWeight: "900"}}>
              VRBO: Trip Board Reimagined
            </h1>
            
            <p style={{ color: 'gray', fontWeight: "500", textAlign: "center", paddingTop:"0.5em"}}>
              Improving the group-booking experience through research and design
            </p>
            {/* <p style={{ color: 'gray', fontWeight: "300"}}>
              5 min read
            </p> */}
            <Image src={image} className="img-fluid w-100 object-fit-cover"  alt="VRBO Header" />
          </Col >
        </Row>
      </div>
      <Row className="align-items-left justify-content-center mx-0 my-5">
        <Col md={6} className='my-5'>
          <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
            <b style={{fontWeight: "800"}}>Imagine trying to plan a group vacation but instead of excitement, you’re hit with endless discussions, lost messages, and decisions that drag on forever. That’s the reality for many VRBO users, until now!</b> We identified the pain points of group bookings, from decision fatigue to information overload, and redesigned VRBO’s "Trip Boards" to turn chaos into clarity. With research backed from user interviews and usability tests, we’re adding some smart features to make group planning a breeze, ensuring smoother booking processes, happier travelers, and property owners who will love the extra bookings.
          </p>
          <p style={{ color:"gray", textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
            In this case study, I'll walk you through the two research approaches we used to make group booking on VRBO a breeze, turning planning chaos into smooth collaboration.
          </p>
        </Col>
      </Row>
      <Row className="align-items-flex-start justify-content-center mx-0 my-5 backgroundcolor">
        <Col md={9} className='my-5'>
          <h2 className="pb-4" style={{color:"gray"}}>
            Quick Facts
          </h2>
          <ProjectInfo />
        </Col>
      </Row>
      <Row  className="align-items-center justify-content-center mx-0 pt-5" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title'>
            THE PROBLEMS
          </p>
          <h2 className="py-2 section-heading">
            Why Planning Group Trips Can Be A Nightmare
          </h2>
          <Row className="align-items-start justify-content-center mx-0 pt-3" style={{textAlign:"justify"}}>
            <Col md={10} style={{lineHeight: "1.6em", paddingTop:"1em", paddingBottom:"1em", fontSize:"20px"}}>
              <Row className='pb-3'>
                We’ve all been there, starting a group chat to plan a trip, only for the plans to never make it out of the chat. Planning group trips can feel overwhelming with unclear options, constant back-and-forth communication, and tough decisions. This hurts not only the one's travelling but also the property owners who miss out on bookings. In our research, we conducted competitor and heuristic analyses, examining the big players - Airbnb and Booking.com. When comparing, we found that while they offer additional options for planning adventures, they don't make group decision-making any easier.
              </Row>
              <Row>
                <b style={{fontWeight: "800"}}>So, how do we stand out? </b>
                We saw an opportunity in the group booking process, mainly the decision-making aspect. Our goal was to build a tool that makes it simple for groups to compare rentals, communicate easily, and make decisions faster, taking the stress out of planning and making trips actually happen.
              </Row>
              <Research />
            </Col>                   
          </Row>
        </Col>
      </Row>  
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4 backgroundcolor" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title' >
            OUR USERS
          </p>
          <h2 className="py-2 section-heading" >
            Meet Our Adventurous Traveller
          </h2>
          <Row className="justify-content-center mx-0 pt-3 mb-2" style={{textAlign:"justify"}}>
            <Col md={10} style={{fontSize:"20px",  lineHeight: "1.6em", paddingBottom:"1em", }}>
              Once we identified an opportunity from our preliminary research, we wanted to hear directly from our users about how this gap impacted them. We screened participants and selected six travelers who love taking the lead in trip planning and interviewed them. These planners shared their struggles, which helped us uncover some great insights to improve the booking experience to make it faster, smoother, and ensuring group collaboration.
            </Col>
            <Col md={10} style={{fontSize:"20px",  lineHeight: "1.6em", paddingBottom:"1em", }}>
              After the interviews, we analysed the data, coded it and created our persona and an ideal journey map to guide our design ideas.
            </Col>
            <Content />
            <Col md={10} style={{fontSize:"20px", textAlign:"center",  lineHeight: "1.6em", paddingBottom:"1em", }}>
              We listed "How Might We" statements for design ideas and to address the findings through the interviews.
              <Row className='mt-2'>
                <Col className="my-4" >
                  <h2 style={{color:"gray", fontSize:"2rem", fontWeight: "900"}}>
                    “How might we enhance collaboration in researching properties and streamline decision-making for bookings?”
                  </h2>
                </Col>
                <Col className="my-4">
                  <h2 style={{color:"gray", fontSize:"2rem", fontWeight: "900"}}>
                    “How might we create an engaging platform to compare vacation rental options efficiently as a group?”
                  </h2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row> 
      <Row  className="align-items-center justify-content-center mx-0 pt-5" style={{textAlign:"center"}}>
        <Col md={10} className='py-2'>
          <p className='my-0 section-title'>
            USER TESTS
          </p>
          <h2 className="py-2 section-heading">
            Putting Trip Boards To The Test
          </h2>
          <Row className="align-items-left justify-content-center mx-0 py-3">
            <Col md={9}>
              <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
                To find out what our users actually do (not just what they say), we ran usability tests with participants picked through a screener. We set up four tasks based on Decision-Making, Information Clarification, Confirming Accommodation, and Trip Modifications each designed to match the project’s goals and highlight any user struggles.
              </p>
              <p style={{textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
              We used a color-coding method to analyze the data, making it easier to identify recurring themes. Below are screenshots from the usability report, giving a quick overview of how we approached the analysis.
              </p>
            </Col>
            <UsabilityResearch />
          </Row>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4 backgroundcolor" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title' >
            RECOMMENDATIONS
          </p>
          <h2 className="py-2 section-heading" >
            Making Group Trips Great Again!
          </h2>
          <Row className="align-items-left justify-content-center mx-0 py-3">
            <Col md={12}>
              <p style={{textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
                We took the main insights from our research and focused on the biggest issues that were slowing down group trip planning. Here are our recommendations, along with the design changes we’ve proposed, to make group trips smoother and more enjoyable!
              </p>
            </Col>
          </Row>
          <VRBOImages />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4" style={{textAlign:"center"}}>
        <Col md={6} className='py-2'>
          <p className='my-0 section-title' >
            RETROSPECTION
          </p>
          <h2 className="py-2 section-heading" >
            Lessons Learned & Next Steps
          </h2>
          <Row className="mx-0 pt-5 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>IMPLEMENTATION OF DESIGN RECOMMENDATIONS</p>
              <p style={{fontSize:"20px"}}>While we did not formally pitch our design suggestions to the brand, we noticed that some of our ideas have been implemented in their recent updates. This alignment between our research findings and the brand’s initiatives highlights the relevance and potential impact of the proposed changes. It’s rewarding to see how user-centered design principles can inspire real-world applications, further enhancing the user experience for travelers.</p>
            </Col>
          </Row>
          <Row className="mx-0 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>LISTENING IS KEY TO UNCOVER INSIGHTS</p>
              <p style={{fontSize:"20px"}}>This being my first deep dive into research, I quickly learned one of the golden rules of user-centered design—put the users first and check your biases at the door. It was a bit tricky at first, but once I shifted to really listening and doing less talking, I uncovered way more insights than I ever had in previous projects. Turns out, focusing on the user makes all the difference!</p>
            </Col>
          </Row>
          <Row className="mx-0 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>THE "AHA" MOMENT</p>
              <p style={{fontSize:"20px"}}>My first in-person interviews and usability tests revealed something important: what users say, what they do, and what they think they do are often worlds apart! This taught me why combining multiple research methods is key to truly understanding user behavior.</p>
            </Col>
          </Row>
          <Row className="mx-0 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>WHAT'S NEXT: KEEP IMPROVING!</p>
              <p style={{fontSize:"20px"}}>Next up, we want to run additional sets of usability test on the redesigned screens. This in turns allows us to validate our recommendations and helps us refine the screens better for the next iteration.</p>
            </Col>
          </Row>
        </Col>
      </Row> 
      <Row className="align-items-center justify-content-center mx-0 py-5 my-2" style={{textAlign:"center"}}>
        <Col md={6}>
          <p style={{ color:"gray", fontSize:"42px", fontWeight: "600", lineHeight: "1.6em"}}>Thank you for Reading!</p>
          <p style={{  fontSize:"20px", lineHeight: "1.6em"}}>I’d love to connect and hear your thoughts! Feel free to reach out to me via email at <a href='mailto:work.voleta@gmail.com'>work.voleta@gmail.com.</a> You can also check out the full case study on <a href="https://www.behance.net/gallery/193101087/VRBO-Trip-Board-Reimagined" target="_blank" rel="noopener noreferrer">Behance</a> for a more in-depth look.</p>
        </Col>  
      </Row>
    </Container>
  );
};

export default SkillSwap;