import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Include Lightbox CSS
import '../css/skill-swap.css';

// Import images directly
import recommendation1 from '../assets/recommendation1.png';
import recommendation2 from '../assets/recommendation2.png';
import recommendation3 from '../assets/recommendation3.png';
import recommendation4 from '../assets/recommendation4.png';

const content = [
  { img: recommendation1, heading: 'Trip Boards vs. My Trips', text: 'We’ve enhanced the visibility of Trip Boards with engaging icons, making it simple to locate! Additionally, ‘My Trips’ has been relocated to the User Profile for easier management of your booked trips. This clear distinction helps users easily differentiate between saved and booked trips, streamlining the journey planning process.' },
  { img: recommendation2, heading: 'From Potential to Saved Trips', text: "We’ve updated the label for better clarity, making it easy for users to go from dreaming to booking! We’ve also introduced a neat feature that shows the members collaborating on each trip. With a clean landscape view of trips, it’s now simpler than ever to see how many adventures are in the works. Let the planning begin!" },
  { img: recommendation3, heading: 'Collaborate Like a Pro', text: 'Planning with friends just got better! Invite users to join the trip board and start a conversation. With features for likes and dislikes, you’ll know instantly who’s vibing with which destination—no more endless debates!' },
  { img: recommendation4, heading: 'Comparisons Made Eaiser', text: "Can't decide between that cozy cabin or beach house? Use our handy comparison tool! Line up your saved spots, compare the pros and cons, and amenities being offered. Decision-making just got a lot easier!" },
];

const VRBOImages = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Track if the image is loading

  const handleImageClick = (index) => {
    setLoading(true); // Set loading state when clicking
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Preload images when the component mounts
  useEffect(() => {
    const preloadImages = () => {
      content.forEach(item => {
        const img = new window.Image();
        img.src = item.img; // Preload the image
      });
      setLoading(false); // Set loading to false after preloading
    };

    preloadImages();
  }, []);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once image is loaded
  };

  return (
    <>
      <Row className="justify-content-between align-items-center mx-0 py-3" style={{ textAlign: "justify" }}>
        {content.map((item, index) => (
          <React.Fragment key={index}>
            {index % 2 === 0 ? (
              <>
                {/* Image on the left, text on the right for even index */}
                <Col 
                  xs={12} 
                  md={7} 
                  className="py-4 mb-4" 
                  style={{ fontSize: "20px", lineHeight: "1.6em", paddingBottom: "1em" }}>
                  <Image 
                    src={item.img} 
                    alt={item.heading} 
                    fluid
                    onClick={() => handleImageClick(index)}
                    style={{ cursor: 'pointer', maxWidth: "100%" }} 
                  />
                </Col>
                <Col xs={12} md={4} className="py-4 mb-4">
                  <h3 style={{color:"gray"}} className='pb-2'>{item.heading}</h3>
                  <p style={{fontSize:"20px"}}>{item.text}</p>
                </Col>
              </>
            ) : (
              <>
                {/* Text on the left, image on the right for odd index */}
                <Col xs={12} md={4} className="py-4 mb-4">
                  <h3 style={{color:"gray"}} className='pb-2'>{item.heading}</h3>
                  <p style={{fontSize:"20px"}}>{item.text}</p>
                </Col>
                <Col 
                  xs={12} 
                  md={7} 
                  className="py-4 mb-4" 
                  style={{ fontSize: "20px", lineHeight: "1.6em", paddingBottom: "1em" }}>
                  <Image 
                    src={item.img} 
                    alt={item.heading} 
                    fluid
                    onClick={() => handleImageClick(index)}
                    style={{ cursor: 'pointer', maxWidth: "100%" }} 
                  />
                </Col>
              </>
            )}
          </React.Fragment>
        ))}
      </Row>

      {/* Lightbox for full-screen image viewing */}
      {isOpen && (
        <Lightbox
          mainSrc={content[photoIndex].img}
          nextSrc={content[(photoIndex + 1) % content.length].img}
          prevSrc={content[(photoIndex + content.length - 1) % content.length].img}
          onCloseRequest={() => setIsOpen(false)}
          onImageLoad={handleImageLoad} // Track image loading
          onMovePrevRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + content.length - 1) % content.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + 1) % content.length);
          }}
        />
      )}

      {/* Loader display while images are loading */}
      {loading && isOpen && <div className="loader">Loading...</div>}
    </>
  );
};

export default VRBOImages;
