import React, {useState, useEffect} from 'react';
import { Link, Element } from 'react-scroll';
import { Container, Row, Col } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import ProjectCard from '../components/ProjectCard'

  const BouncingArrow = ({ isBouncing }) => (
    <div className="arrow-container">
      <Link to="projects" smooth={true} duration={500}>
        <FaChevronDown
          className={isBouncing ? 'bounce' : ''}
          style={{ fontSize: "45px", color: "gray", transition: "transform 0.3s" }}
        />
      </Link>
    </div>
  );

const Portfolio = () => {
    const [bouncing, setBouncing] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const projects = [
        {
            name: "Skill Swap: Bridging Learning and Earning",
            caseStudyLink:"/skill-swap",
            projectType: ["UI Design", "Usability Testing", "User Flow", "Information Architecture"],
            imageSrc: require("../assets/skill-swap.png"),
        },
        {
            name: "VRBO Trip Board Reimagined",
            caseStudyLink:"/vrbo",
            projectType: ["User Research", "Usability Testing", "Interface Design"],
            imageSrc: require("../assets/vrbo.jpg"),
        },
        {
            name: "Clever Cooks",
            caseStudyLink:"/clever-cooks",
            projectType: ["Information Architecture", "Usability Testing", "Web Development", "UI Design"],
            imageSrc: require("../assets/clevercooks.png"),
        },
        // {
        //     name: "ReMed: Your Friendly Medication Sidekick",
        //     caseStudyLink:"https://www.behance.net/gallery/169480545/ReMed-Medication-Reminder-App",
        //     projectType: ["User Research", "Usability Testing", "UI Design", "Interface Design"],
        //     imageSrc: require("../assets/remed.png"),
        // },
        // {
        //     name: "FurKids: Your Paw-some Adoption Adventure!",
        //     caseStudyLink:"https://www.behance.net/gallery/169556511/FurKids-Pet-Adoption-Website",
        //     projectType: ["User Interview", "Information Architecture", "Rapid Prototype", "Wireframing"],
        //     imageSrc: require("../assets/furkids.png"),
        // },
        // {
        //     name: "Optimising Online Shopping",
        //     caseStudyLink:"https://www.behance.net/gallery/192025437/Roots-Research-Proposal-for-Optimisations",
        //     projectType: ["Stakeholder Interview", "Usability Testing", "A/B Testing", "Research Plan"],
        //     imageSrc: require("../assets/Rootspng.png"),
        // },
        // {
        //     name: "Redesign - Pennyjuice Website",
        //     caseStudyLink:"",
        //     projectType: ["UI Design", "Research", "Infromation Architecture", "Rapid Prototyping"],
        //     imageSrc: require("../assets/pennyjuicecomingsoon.png"),
        // },
    ];
    return (
      <Container fluid className="mx-0 px-0">
            <div 
  className="px-3 background-image" 
>
            <Row className="align-items-center justify-content-center" style={{ minHeight: "40vh"}}>
                {/* Left Column */}
            <Col md={6} className="text-center mb-4">
                <h1 className="responsive-heading mb-4" style={{ fontWeight: "900", marginBlockStart: '2.5em' }}>
                Elevating User Experiences With Creativity and Curiosity
                </h1>
                <p className="lead" style={{ color: 'black', fontWeight: "500", lineHeight: "1.6em", textAlign: "center", paddingTop:"1em", paddingBottom:"1em"}}>
                    Hi! I'm Voleta Noronha, A passionate UX and UI Designer and I'm all about solving problems through creativity, collaboration, and a user-centered approach. With both design and development experiences, I bring effective solutions to drive team success. Inspired by Hermione Granger’s relentless pursuit of knowledge, I’m always eager to explore and advance in tech.
                </p>
                <Link to="projects" smooth={true} duration={500}>
                <p
          className="my-2"
          style={{ color: 'gray', fontWeight: "500", lineHeight: "1.6em", textAlign: "center" , cursor: "pointer"}}
          onMouseEnter={() => setBouncing(true)}
          onMouseLeave={() => setBouncing(false)}
        >
          SCROLL TO SEE MY LATEST WORK
        </p>
        </Link>
            </Col >
            </Row>
            
            <BouncingArrow isBouncing={bouncing} />
            </div>           
            {/*projects */}
            <Element name="projects" className="element">
            <Row id="projects" className="mx-0 align-items-center justify-content-center  py-5 d-flex flex-column justify-content-left">
                {projects.map((project, index) => (
                    <Col key={index} className='my-3'>
                        <ProjectCard {...project} className=''/>
                    </Col>
                ))}
            </Row>
            </Element>
        </Container>
        
    );
};

export default Portfolio;