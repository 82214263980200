import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../css/ProjectCard.css'; // Assuming you have a separate CSS file for styling
import '../css/common.css';
import { Link } from 'react-router-dom';

const ProjectCard = (props)=>{
    const { name, caseStudyLink, projectType, imageSrc } = props;
    return (
        <div className={`project-card-container mb-2 `} style={{ minHeight: "400px" }}>
    <Container className=" project-card-box px-0">
    <Link 
    to={caseStudyLink} 
    target="_blank" // Opens the link in a new tab
    rel="noopener noreferrer" 
    style={{
        textDecoration: "none",  // Removes underline
        color: "inherit"  // Inherits color from parent or allows you to set a specific color
    }}
>
        <Row xs={12} lg={12} className="px-0 justify-content-center  project-details align-items-center mx-0"> {/* Center content */}
            
                    {/* Left Side (Image) */}
                    <Col xs={12} md={7} className={`project-image-container text-left px-0`}> {/* px-0 to remove padding */}
                        
                            <img 
                                src={imageSrc} 
                                alt="Project Image" 
                                className="img-fluid project-image" 
                                style={{
                                    width: "820px",  /* Fixed width */
                                    height: "452px", /* Fixed height */
                                    objectFit: "cover"  /* Ensures image fills the box, maintaining aspect ratio */
                                }} 
                            />
                        
                    </Col>

                    {/* Right Side (Title, Year, Skills) */}
                    <Col xs={12} md={5} className="text-center text-md-center">
                    <h2 
    className="mb-4" 
    style={{
        fontWeight: "700", 
        fontSize: "24px", 
        lineHeight: "1.6em", 
        width: "433px",  // Fixed width for larger screens
        maxWidth: "100%",  // Responsive for smaller screens
        textAlign: "center",  // Center the text
        margin: "0 auto"  // Center the block itself
    }}
>
    {name}
</h2>

                        <div className="project-types mb-4 d-flex flex-wrap" style={{width: "433px",  // Fixed width for larger screens
        maxWidth: "100%",  // Responsive for smaller screens
        textAlign: "center",  // Center the text
        margin: "0 auto"  }}>
                            {projectType.map((type, index) => (
                                <div key={index} className="project-type-rectangle mb-3">
                                    {type}
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
 
        </Link>
    </Container>
</div>


    );
};

export default ProjectCard;
