import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import IA from '../assets/information-architecture.png';
import userflow from '../assets/userflow-cleverl.png';

const ResearchGallery = () => {
  const images = [
    // { src: mission, alt: 'Product mission and vision' },
    { src: IA, alt: 'Information architecture for clever cooks', description:"Information Architecture was created in a way to help us understand the pages we requires the additional content that individuals pages and components would be made of" },
    { src: userflow, alt:"User flow of the clever cooks website", description: 'An ideal userflow that a user would follow when using our website' },
  ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Track if the image is loading

  const handleImageClick = (index) => {
    setLoading(true); // Set loading state when clicking
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Preload all images when the component mounts
  useEffect(() => {
    images.forEach(image => {
      const img = new window.Image();
      img.src = image.src;
    });
  }, []);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once image is loaded
  };

  return (
    <>
      <Row className="gallery my-4 align-items-start justify-content-center">
        {images.map((image, index) => (
          <Col md={10} key={index} className="mb-4">
            <Image 
              src={image.src} 
              alt={image.alt} 
              fluid 
              onClick={() => handleImageClick(index)} 
              style={{ cursor: 'pointer', width: '100%' }} 
            />
            <p className='mt-3' style={{color:"gray"}}>{image.description}</p>
          </Col>
        ))}
      </Row>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onImageLoad={handleImageLoad} // Track image loading
          onMovePrevRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + 1) % images.length);
          }}
        />
      )}

      {loading && isOpen && <div className="loader">Loading...</div>} {/* Optional loader display */}
    </>
  );
};

export default ResearchGallery;
