import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import paperprototype from '../assets/paper-prototype.png';
import digitalwireframes from '../assets/digital-wireframes.png'; // Use actual images
import refinedwireframes from '../assets/refined-wireframes.png'; // Use actual images
import styleguide from '../assets/style-guide.png'; // Use actual images

const Images = () => {
    const images = [
        { src: paperprototype, alt: 'Iteration 1 Paper Prototype', description: "For our first iteration of screens we decided to use paper prototypes for a faster output. This way our team was able to annotate and point out the features that were important to us and start working on getting them ready digitally" },
        { src: digitalwireframes, alt: 'Iteration 2 Digital Wireframes', description: "After the paper prototypes, we moved them to digital screens. Around this time, we met with our mentor, who guided us to use simple design systems and start incorporating the actual copy for the app to get it ready for testing. This phase taught us the value of failing fast. We aimed to quickly prepare the digital wireframes, get them tested by users, and make the necessary changes in the next iteration" },
        { src: refinedwireframes, alt: 'Iteration 3 Refined Wireframes after Test', description: "Post the tests we conducted, changes were mainly for screens that were functionally difficult. We addressed the location issue by adding an option to change radius and select location using maps. Options for users to edit booking was one of the major aspect we missed covering in our initial iterations." },
        { src: styleguide, alt: 'Style Guide', description: "We kept things simple with a clean color palette and made sure to create reusable components. This helped us keep the design consistent across all the screens and made everything easier to work with. Having the style guide in place, we could focus more on the functionality and address the changes based on the tests conducted" },
    ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Track if the image is loading

  const handleImageClick = (index) => {
    setLoading(true); // Set loading state when clicking
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Preload all images when the component mounts
  useEffect(() => {
    images.forEach(image => {
      const img = new window.Image();
      img.src = image.src;
    });
  }, []);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once image is loaded
  };

  return (
    <>
      <Row className="gallery my-4 flex-column align-items-center">
        {images.map((image, index) => (
          <Col md={9} key={index} className="mb-4">
            <Image 
              src={image.src} 
              alt={image.alt} 
              fluid 
              onClick={() => handleImageClick(index)} 
              style={{ cursor: 'pointer', width: '100%' }} 
            />
            <p className="my-3" style={{ color: "gray", fontSize: "18px", fontWeight: "500", lineHeight: "1.6em" }}>
              {image.description}
            </p>
          </Col>
        ))}
      </Row>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onImageLoad={handleImageLoad} // Track image loading
          onMovePrevRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + 1) % images.length);
          }}
        />
      )}

      {loading && isOpen && <div className="loader">Loading...</div>} {/* Optional loader display */}
    </>
  );
};

export default Images;
