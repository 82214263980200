import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import SkillSwap from "./pages/projects/skill-swap";
import Vrbo from "./pages/projects/vrbo";
import CleverCooks from "./pages/projects/clever-cooks";
import Footer from "./components/Footer";
import ScrollToTopArrow from "./components/ScrollToTopArrow";
import ScrollToTop from "./components/ScrollToTop";
import './css/common.css';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/skill-swap" element={<SkillSwap />} />
        <Route path="/vrbo" element={<Vrbo />} />
        <Route path="/clever-cooks" element={<CleverCooks />} />
      </Routes>
      <ScrollToTopArrow />
      <Footer />
    </Router>
  );
};

export default App;
