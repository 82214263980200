import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();  // Get the current path
  
    useEffect(() => {
      window.scrollTo(0, 0);  // Scroll to top
    }, [pathname]);  // Trigger this effect whenever the route changes
  
    return null;  // This component doesn't need to render anything
  };
  
  export default ScrollToTop;