import React from 'react';
import { Row, Col} from 'react-bootstrap';
import '../css/skill-swap.css';

const content = [
    { img: require("../assets/login.gif"), heading: 'Quick, Simple, and Personalized Setup', text: 'The login and onboarding process is designed to be quick and easy, allowing users to get started with minimal effort. After logging in, users can specify their areas of interest, making the app more convenient and tailored to their preferences from the get-go.' },
    { img: require("../assets/searchandfilter.gif"), heading: 'Customized Learning with Precision', text: 'On the home page, you can set a radius to receive personalized learning suggestions based on your location. The filters allow you to search by distance, preferred learning time, and price range, offering a highly customized and personalized search experience.' },
    { img: require("../assets/booking.gif"), heading: 'Simple, Flexible, and Detailed', text: 'The booking process is straightforward, with clear instructions and multiple options for selecting dates and times that suit both the learner and the expert. Once confirmed, you can easily add the booking to your in-app calendar, and you’ll receive app notifications to remind you and a notification bar on the homepage.' },
    { img: require("../assets/updatecalendar.gif"), heading: 'Flexible Scheduling & Easy Notifications', text: 'Once a session is booked, you can easily edit or cancel the appointment. If you need to reschedule, simply update the date and time, and both the learner and expert will be notified via email. The app’s calendar will automatically update, keeping everything organized.' },
  ];
  
  const ContentRow = () => {
    return (
      <Row className="justify-content-center align-items-center mx-0 py-3" style={{ textAlign: "justify" }}>
        {content.map((item, index) => (
          <React.Fragment key={index}>
            {index % 2 === 0 ? (
              <>
                {/* Image on the left, text on the right for even index (for larger screens), but in a single column for smaller screens */}
                <Col 
                  xs={12} 
                  md={4} 
                  className="py-4 mb-4" 
                  style={{ fontSize: "20px", lineHeight: "1.6em", paddingBottom: "1em" }}>
                  <img src={item.img} alt={item.heading} style={{ maxWidth: "100%" }} />
                </Col>
                <Col xs={12} md={6} className="py-4 mb-4">
                  <h3 style={{color:"gray"}} className='pb-2'>{item.heading}</h3>
                  <p style={{fontSize:"20px"}}>{item.text}</p>
                </Col>
              </>
            ) : (
              <>
                {/* Text on the left, image on the right for odd index (for larger screens), but in a single column for smaller screens */}
                <Col xs={12} md={6} className="py-4 mb-4">
                <h3 style={{color:"gray"}} className='pb-2'>{item.heading}</h3>
                  <p style={{fontSize:"20px"}}>{item.text}</p>
                </Col>
                <Col 
                  xs={12} 
                  md={4} 
                  className="py-4 mb-4" 
                  style={{ fontSize: "20px", lineHeight: "1.6em", paddingBottom: "1em" }}>
                  <img src={item.img} alt={item.heading} style={{ maxWidth: "100%" }} />
                </Col>
              </>
            )}
          </React.Fragment>
        ))}
      </Row>
    );
  };
  
  export default ContentRow;
  