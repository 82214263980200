import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import wireframe1 from '../assets/wireframe1.png';
import wireframe2 from '../assets/wireframe2.png'; // Use actual images
import styleguide from '../assets/style-guide-clever.png'; // Use actual images
import wireframe3 from '../assets/wireframe3.png'; // Use actual images

const Images = () => {
    const images = [
        { src: wireframe1, alt: 'Iteration 1 Paper Prototype', description: "We decided to draw out certain components and design ideas on paper before we moved them digitally" },
        { src: wireframe2, alt: 'Iteration 2 Digital Wireframes', description: "For our digital wireframes we included the screens that we would need to complete the flow. Over the course of our iterations, we changed the design quite a bit wihch will be covered in the case study further" },
        { src: styleguide, alt: 'Style Guide for Clever Cooks', description: "We used colors that resonated 'FOOD'. We considered online resources to help us choose the palette. We also created reusable components which have been further updated. For me, this was my first style guide created, so I made sure to include as much details on it as possible" },
        { src: wireframe3, alt: 'Mockups for our website', description: "Using the style guide we went ahead to add some color and branding to our wireframes. We even added copy that worked well for our website" },
    ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Track if the image is loading

  const handleImageClick = (index) => {
    setLoading(true); // Set loading state when clicking
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Preload all images when the component mounts
  useEffect(() => {
    images.forEach(image => {
      const img = new window.Image();
      img.src = image.src;
    });
  }, []);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once image is loaded
  };

  return (
    <>
      <Row className="gallery mt-5 flex-column align-items-center">
        {images.map((image, index) => (
          <Col md={9} key={index} className="mb-4">
            <Image 
              src={image.src} 
              alt={image.alt} 
              fluid 
              onClick={() => handleImageClick(index)} 
              style={{ cursor: 'pointer', width: '100%' }} 
            />
            <p className="mt-3" style={{ color: "gray", fontSize: "18px", fontWeight: "500", lineHeight: "1.6em" }}>
              {image.description}
            </p>
          </Col>
        ))}
      </Row>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onImageLoad={handleImageLoad} // Track image loading
          onMovePrevRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + 1) % images.length);
          }}
        />
      )}

      {loading && isOpen && <div className="loader">Loading...</div>} {/* Optional loader display */}
    </>
  );
};

export default Images;
