import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Include Lightbox CSS
import '../css/skill-swap.css';

// Import images directly
import recommendation1 from '../assets/recommendation1-celver.png';
import recommendation2 from '../assets/recommendation2-clever.png';
import recommendation3 from '../assets/recommendation3-clever.png';

const content = [
  { img: recommendation1, heading: 'Redefining The Journey', text: "Our users saw the site as just a 'RECIPE FINDER,' which wasn’t our original vision. On top of that, they found the login/signup process disrupted their flow. So, we revamped things! Now, users only sign up if they want to save recipes, and we trimmed away anything that didn’t align with our mission. The result? A smoother, more purposeful experience!" },
  { img: recommendation2, heading: 'Decluttering The Filters', text: "Filters were cluttered with multiple options making the user overwhelmed. Selecting from the long list was just impractical. We cleared the clutter and opted for a cleaner horizontal drop down and displayed selected options in the search bar." },
  { img: recommendation3, heading: 'Moving from Blah to Yum!', text: 'The most important page wasn’t quite serving up the visual feast it needed to. It was missing that "wow" factor! So, we gave it a makeover, more structure, more style, and a dash of oomph to make it as mouth-watering as the recipes themselves!' },
];

const VRBOImages = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Track if the image is loading

  const handleImageClick = (index) => {
    setLoading(true); // Set loading state when clicking
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Preload images when the component mounts
  useEffect(() => {
    const preloadImages = () => {
      content.forEach(item => {
        const img = new window.Image();
        img.src = item.img; // Preload the image
      });
      setLoading(false); // Set loading to false after preloading
    };

    preloadImages();
  }, []);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once image is loaded
  };

  return (
    <>
      <Row className="justify-content-between align-items-center mx-0 py-3" style={{ textAlign: "justify" }}>
        {content.map((item, index) => (
          <React.Fragment key={index}>
            {index % 2 === 0 ? (
              <>
                {/* Image on the left, text on the right for even index */}
                <Col 
                  xs={12} 
                  md={7} 
                  className="py-4 mb-4" 
                  style={{ fontSize: "20px", lineHeight: "1.6em", paddingBottom: "1em" }}>
                  <Image 
                    src={item.img} 
                    alt={item.heading} 
                    fluid
                    onClick={() => handleImageClick(index)}
                    style={{ cursor: 'pointer', maxWidth: "100%" }} 
                  />
                </Col>
                <Col xs={12} md={4} className="py-4 mb-4">
                  <h3 style={{color:"gray"}} className='pb-2'>{item.heading}</h3>
                  <p style={{fontSize:"20px"}}>{item.text}</p>
                </Col>
              </>
            ) : (
              <>
                {/* Text on the left, image on the right for odd index */}
                <Col xs={12} md={4} className="py-4 mb-4">
                  <h3 style={{color:"gray"}} className='pb-2'>{item.heading}</h3>
                  <p style={{fontSize:"20px"}}>{item.text}</p>
                </Col>
                <Col 
                  xs={12} 
                  md={7} 
                  className="py-4 mb-4" 
                  style={{ fontSize: "20px", lineHeight: "1.6em", paddingBottom: "1em" }}>
                  <Image 
                    src={item.img} 
                    alt={item.heading} 
                    fluid
                    onClick={() => handleImageClick(index)}
                    style={{ cursor: 'pointer', maxWidth: "100%" }} 
                  />
                </Col>
              </>
            )}
          </React.Fragment>
        ))}
      </Row>

      {/* Lightbox for full-screen image viewing */}
      {isOpen && (
        <Lightbox
          mainSrc={content[photoIndex].img}
          nextSrc={content[(photoIndex + 1) % content.length].img}
          prevSrc={content[(photoIndex + content.length - 1) % content.length].img}
          onCloseRequest={() => setIsOpen(false)}
          onImageLoad={handleImageLoad} // Track image loading
          onMovePrevRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + content.length - 1) % content.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + 1) % content.length);
          }}
        />
      )}

      {/* Loader display while images are loading */}
      {loading && isOpen && <div className="loader">Loading...</div>}
    </>
  );
};

export default VRBOImages;
