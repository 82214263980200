import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../../css/skill-swap.css';
import image from '../../assets/clever-cooks-hero-image.png';
import competitor from '../../assets/competitor-analysis.png';
import livesite from '../../assets/livesite.gif';
import Brainstorm from '../../components/Brainstorm';
import Foundation from '../../components/Foundation';
import Wireframes from '../../components/Wireframes';
import CleverImages from '../../components/CleverImages';

const projectDetails = [
    { title: 'ROLE', content: 'Web Developer, UX Tester' },
    { title: 'TOOLS USED', content: 'Figma, Figjam, MS Teams, VSCode, GitHub' },
    { title: 'SKILLS', content: 'Competitor and Market Analysis, Usability Test, Fron-end Code, Wireframing, Information Architecture, Visual Design' },
    { title: 'CLIENT', content: 'Academic Project' },
    { title: 'TIMELINE', content: 'Sept 2022 - Dec 2022' },
    { title: 'TEAM MEMBERS', content: '3' }
  ];
  
  const ProjectInfo = () => (
    <>
      <Row className="pb-4">
        {projectDetails.slice(0, 3).map((detail, index) => (
          <Col key={index}>
            <p className="my-0" style={{ color: "gray", fontWeight: "400" }}>{detail.title}</p>
            <p style={{ fontSize: "20px", lineHeight: "1.6em" }}>{detail.content}</p>
          </Col>
        ))}
      </Row>
      <Row>
        {projectDetails.slice(3).map((detail, index) => (
          <Col key={index}>
            <p className="my-0" style={{ color: "gray", fontWeight: "400" }}>{detail.title}</p>
            <p style={{ fontSize: "20px", lineHeight: "1.6em" }}>{detail.content}</p>
          </Col>
        ))}
      </Row>
    </>
  );

const notes = [
    { text: 'Too many options are overwhelming' },
    { text: 'Dietary preferences are not available' },
    { text: 'Results provided have missing ingredients' },
    { text: 'UI has an overload of information' },
    { text: 'Cannot save receipe to get back to' },
    { text: 'No community to share and upload rceipes' },
    { text: 'Advertisements are distracting' },
    { text: 'Images and icons used not brand specific' },
];

const NotesGrid = () => (
    <Row className="align-items-center justify-content-center mx-0 py-2">
      {notes.map((note, index) => (
        <Col key={index}  xs={10} sm={10} md={4} lg={4} className="stickynotes mb-3">
          {note.text}
        </Col>
      ))}
    </Row>
  );

  const FigmaPrototype = () => {
    return (
      <Col>
        <iframe
            style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
            width="100%"
            height="450"
            src="https://embed.figma.com/proto/jxxvlBpYMNf0pZVaVRnDns/CleverCook-Wireframes?node-id=310-4266&node-type=frame&scaling=scale-down&content-scaling=fixed&page-id=15%3A19&starting-point-node-id=310%3A4266&embed-host=share"
            allowFullScreen
            title="Figma Prototype"
        ></iframe>
      </Col>
    );
  };

const CleverCooks = () => { 
    return (
    <Container fluid className="mx-0 px-0">
      <div className="px-3 backgroundcolor">
        <Row className="align-items-center justify-content-center" style={{ minHeight: "75vh"}}>
          <Col md={8} className="text-center">
            <h1 className="responsive-heading-case-study" style={{ fontWeight: "900"}}>
              Clever Cooks: Think Less, Cook More
            </h1>
            <p style={{ color: 'gray', fontWeight: "500", textAlign: "center", paddingTop:"0.5em"}}>
            Discover the art of cooking delicious meals from what’s in your fridge and save both money and the planet!
            </p>
            {/* <p style={{ color: 'gray', fontWeight: "300"}}>
              5 min read
            </p> */}
            <Image src={image} className="img-fluid w-100 object-fit-cover"  alt="Clever Cooks Header" />
          </Col >
        </Row>
      </div>
      <Row className="align-items-left justify-content-center mx-0 my-5">
        <Col md={6} className='my-5'>
          <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
            <b style={{fontWeight: "800"}}>Are you tired of groceries going to waste while you juggle a busy schedule? Meet Clever Cooks, the innovative solution designed for busy individuals and families who want a hassle-free cooking experience!</b> Just input your available ingredients and let Clever Cooks displays delicious recipes that save you time and reduce waste. Our platform not only helps users make the most of their groceries but also encourages families to live a sustainable lifestyle.
          </p>
          <p style={{ color:"gray", textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
          In this case study, I'll take you through the process of designing and developing our website. The main focus is on wireframing and making design changes that led to the final website.
          </p>
        </Col>
      </Row>
      <Row className="align-items-flex-start justify-content-center mx-0 my-5 backgroundcolor">
        <Col md={9} className='my-5'>
          <h2 className="pb-4" style={{color:"gray"}}>
            Quick Facts
          </h2>
          <ProjectInfo />
        </Col>
      </Row>
      <Row  className="align-items-center justify-content-center mx-0 py-5" style={{textAlign:"center"}}>
        <Col md={10} className='py-2'>
          <p className='my-0 section-title'>
            THE PROBLEMS
          </p>
          <h2 className="py-2 section-heading">
          Wasted Food, Wasted Money. A Recipe for Disaster!
          </h2>
          <Row className="align-items-start justify-content-center mx-0 py-3 mb-2" style={{textAlign:"justify"}}>
            <Row md={12} style={{textAlign:"center"}} className="flex-column align-items-center justify-content-between mx-0">
              <Col md={10} >
                <p className="my-0 py-0" style={{ fontSize:"4em",fontWeight: "900", color:"#2D898B"}}>3X</p>
                <p style={{ color: 'gray', fontWeight: "500"}}>Increase In Food Sales</p>
              </Col>
              <Col md={10}>
                <p className="my-0 py-0" style={{  fontSize:"4em",fontWeight: "900", color:"#2D898B"}}>63%</p>
                <p style={{ color: 'gray', fontWeight: "500"}}>Increase In Food Wastage</p>
              </Col>
            </Row> 
            <Col md={7} style={{lineHeight: "1.6em", paddingTop:"1em", paddingBottom:"1em", fontSize:"20px"}}>
              <Row className='pb-3'>
                Based on our market research, post-COVID, families shifted to home cooking, with food sales tripling, but along with that came a 63% increase in food waste. People are spending more, yet still throwing out groceries, often because they lack time or struggle to think of recipes that fit their dietary needs. Most cooking platforms don't offer personalized options or ways to easily share and engage with others.
              </Row>
              <Row>
                <b style={{fontWeight: "800"}}>Many companies provide receipes online, but how do we stand out? </b>
                We offer customized recipes based on your dietary preferences, a community section to share and explore user-generated recipes, and tools to save, like, and curate collections. With a clear search bar and easy-to-use design, we’re solving food waste and helping you cook smarter, not harder!
              </Row>
            </Col>                   
          </Row> 
          <Row className='justify-content-center align-items-center mt-5'>
            <Col md={7} className='mb-3'>
              <Image src={competitor} fluid />
            </Col>
            <Col md={5}>
              <NotesGrid />
            </Col>
          </Row>
        </Col>
      </Row>  
      <Row className="align-items-center justify-content-center mx-0 pt-5 my-4 backgroundcolor" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title' >
            OUR SOLUTION
          </p>
          <h2 className="py-2 section-heading" >
          Minimize Waste, Maximize Meals!
          </h2>
          <Row className="justify-content-between align-items-start mx-0 py-5" style={{textAlign:"justify"}}>
            <Col md={4} style={{fontSize:"20px",  lineHeight: "1.6em", paddingBottom:"1em", }}>
              <Row className=''>
              We created Clever Cooks, a platform that helps users reduce food waste and save time by generating delicious recipes based on what’s already in their fridge and tailored to their diet preferences! Whether you're a busy professional or a family trying to make the most of your groceries, Clever Cooks offers personalized meal ideas to match your lifestyle and make cooking a breeze.
              </Row>
              <Row className='mt-4' style={{ color:"gray", fontWeight: "400", }}>
                CHECK OUR LIVE SITE
              </Row>
            </Col>
            <Col md={7} ><a href="http://clevercooks.duckdns.org/home.php" target="_blank" // Opens the link in a new tab
    rel="noopener noreferrer"><img src={livesite} alt="A demonstration of the live clever cooks website" style={{ maxHeight: "100%" }} /></a></Col>
          </Row>
        </Col>
      </Row> 
      <Row  className="align-items-center justify-content-center mx-0 pt-5" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title'>
            THE DESIGN JOURNEY
          </p>
          <h2 className="py-2 section-heading">
            Building Blocks of Our Design Process
          </h2>
          <Row className="align-items-left justify-content-center mx-0 py-3">
            <Col md={10}>
              <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
                After our research, it was time to follow it <b style={{fontWeight: "800"}}>what our users really need.</b> So, who are they? Our audience range from home cooks to busy bees, health-conscious foodies, and anyone searching for recipe ideas. Their goals? Spending more time cooking with family, living more sustainably, and leveling up their kitchen skills. And what drives them? Saving time, saving money, and eating healthier. With all this in the mix, we shaped a mission and vision to build a platform that makes cooking easier, smarter, and way more fun! 
              </p>
              <p style={{ color:"gray", textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
              Brainstorming was the most exciting part of our ideation phase! We had multiple ideas and paths our product could take. But finally, we narrowed things down to keep things on track. This was super important to get the ball rolling and start bringing our vision to life!
              </p>
            </Col>
            <Brainstorm />
          </Row>
          <h3 className="my-4" style={{color:"gray", fontSize:"1.5rem", fontWeight: "900"}}>
            Working to build the foundation
          </h3>
          <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}} className='mb-3'>
            We worked on the information architecture, setting up the website's structure before moving into coding. This was important to give us a clear roadmap for the content and pages. Refining the user flow through a few iterations gave us a smooth, user-centeric journey that ticks all the right boxes for our users.
          </p>
          <Foundation />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4 backgroundcolor" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title' >
            DESIGN, TEST AND DELIVER
          </p>
          <h2 className="py-2 section-heading" >
            From Blueprints to Final Designs
          </h2>
          <Row className="align-items-left justify-content-center mx-0 py-3">
            <Col md={12}>
              <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
                Our sketches and digital wireframes, brought our ideation phase to life! We also created a style guide, which became our design bible, making sure every component was consistent. With the wireframes and style guide in hand, we then built the mockups, which were then put to the test!
              </p>
              <Wireframes />
              <h3 className="my-4" style={{color:"gray", fontSize:"1.5rem", fontWeight: "900"}}>
                Test and Redesign
              </h3>
              <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}} className='mb-3'>
                We tested our first iteration of the mockup with our users. Using the feedback and insights gained we made design changes.
              </p>
              <CleverImages />
              <h3 className="my-4" style={{color:"gray", fontSize:"1.5rem", fontWeight: "900"}}>
                Final Product
              </h3>
              <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}} className='mb-3'>
                With our design changes in place, our prototype is ready for handover. You can also check out the <a href="http://clevercooks.duckdns.org/home.php">live website</a>  we’ve launched!
              </p>
              <FigmaPrototype />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4" style={{textAlign:"center"}}>
        <Col md={6} className='py-2'>
          <p className='my-0 section-title' >
            RETROSPECTION
          </p>
          <h2 className="py-2 section-heading" >
            Lessons Learned & Next Steps
          </h2>
          <Row className="mx-0 pt-5 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>THE SECRET INGREDIENT OF USER FEEDBACK</p>
              <p style={{fontSize:"20px"}}>One thing we could have improved was involving users from the get-go. I learned just how crucial usability tests are for validating designs. They help us check our biases at the door and provide valuable insights, ensuring that each iteration brings us closer to a polished and user-friendly website!</p>
            </Col>
          </Row>
          <Row className="mx-0 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>BRIDGING CODE AND CREATIVITY</p>
              <p style={{fontSize:"20px"}}>This project also focused on building a functional website, giving me the chance to exercise my coding skills. I got to experience the handoff process, balancing design with technical realities. Knowing the ins and outs of what could be coded and what was too complex, I created clean, functional designs that met both business goals and user needs!</p>
            </Col>
          </Row>
          <Row className="mx-0" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>WHAT'S COOKING NEXT</p>
              <p  style={{fontSize:"20px"}}>Our next move is to build a community page where users can share personalized recipes, tips, and ideas. We’ll also continue with more usability tests and focus groups to better understand how users plan their meals and develop their cooking habits.</p>
            </Col>
          </Row>
        </Col>
      </Row> 
      <Row className="align-items-center justify-content-center mx-0 py-5 my-2" style={{textAlign:"center"}}>
        <Col md={6}>
          <p style={{ color:"gray", fontSize:"42px", fontWeight: "600", lineHeight: "1.6em"}}>Thank you for Reading!</p>
          <p style={{  fontSize:"20px", lineHeight: "1.6em"}}>I’d love to connect and hear your thoughts! Feel free to reach out to me via email at <a href='mailto:work.voleta@gmail.com'>work.voleta@gmail.com.</a> You can also check out the full case study on <a href="https://www.behance.net/gallery/193386579/Clever-Cooks-Case-Study" target="_blank" rel="noopener noreferrer">Behance</a> for a more in-depth look.</p>
        </Col>  
      </Row>
    </Container>
  );
};

export default CleverCooks;