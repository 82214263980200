import React from 'react';
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap';
import { FaLinkedinIn, FaDatabase, FaCode, FaPencilAlt, FaSearch, FaBehance, FaSitemap, FaUserCheck, FaMagic, FaClock, FaEnvelope} from 'react-icons/fa';
import image from '../assets/new-profile-crop.jpg';
import logo from '../assets/voleta-logo-removebg-preview.png';
import '../css/About.css';
import '../css/common.css';

const Skills = ({ icon, skill }) => {
    return (
        <Card className="text-center border-0 mx-4" style={{backgroundColor:"#fbfcf8"}}>
            <Card.Body style={{width: '200px'}}>
            <div className="border border-5 rounded-circle p-3 mx-auto mb-3 d-flex justify-content-center align-items-center"
            style={{
                width: '80px',
                height: '80px',
                fontSize: '1.2rem',
            }}
            >
                {icon}
            </div>
                <Card.Title style={{ fontSize: '1rem' }}>{skill}</Card.Title>
            </Card.Body>
        </Card>
    );
};

const About = () => {

    
  return (

    <Container className="my-5">
        {/* <h1>About Me</h1> */}
        <Row className=" my-4 align-items-top">
            <Col className="d-flex align-items-stretch" md={5} sm={12}>
            <Image 
    src={image} 
    alt="Voleta-Profile-Pic" 
    className="img-fluid w-100 object-fit-cover" 
    style={{
        height: "80%",   
    }} 
/>
            </Col>
            <Col md={1} sm={12} className='mb-4'></Col>
            <Col md={6} sm={12} className='mb-4'>
                <h1 className=" mb-4"style={{fontWeight: "600", fontSize: "36px"}}>
                    Hi there! I'm so glad you've found your way here :)
                </h1>

                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                Let me take you on a little trip through my journey. It all started at <b style={{ fontSize:"20px", fontWeight: "900"}}>a web hosting company,</b> where I got to flex both my technical and creative skills, helping customers bring their websites and businesses to life. Little did I know, this is where I belonged. <b style={{ fontSize:"20px", fontWeight: "900"}}>My passion for user-centered design and crafting smooth, impactful user experiences was born </b>and I’ve been loving every moment since!
                </p>

                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                Curious to explore this newfound love for UX, I packed up my stuff into two suitcases and journeyed halfway around the globe to <b style={{ fontSize:"20px", fontWeight: "900"}}>Humber College in Toronto. </b>Here I refined my skills in Research, Wireframing, Prototyping, and Interaction Design. Now, get to <b style={{ fontSize:"20px", fontWeight: "900"}}>blend my tech and creative skills to develop innovative solutions that make a real impact.</b>
                </p>
                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                When I’m not designing, you’ll likely find me exploring new trails on a hike or diving into a great book. Right now, I’m completely hooked on The 'Guest List' by Lucy Foley. It’s a must-read for mystery enthusiasts!
                </p>
                <Button href="https://drive.google.com/file/d/1edD8aTjx4I1ahEeXtG3jBcDtEvojeILd/view?usp=sharing" target="_blank" rel="noopener noreferrer" className='button-primary' variant="primary">
                    
                    View Resume
                </Button>
                <div className="social-links" style={{ fontSize: "24px", marginTop: "5px"}}>
                    <a href="mailto:work.voleta@gmail.com" target="_blank" rel="noopener noreferrer"><FaEnvelope style={{ marginRight: "15px" }} /></a>
                    <a href="https://www.linkedin.com/in/voleta-noronha/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn style={{ marginRight: "15px" }} /></a>
                    <a href="https://www.behance.net/voletanoronha/" target="_blank" rel="noopener noreferrer"><FaBehance style={{ marginRight: "15px" }} /></a>
                </div>
            </Col>
        </Row>

        
        <Row className=" my-3 align-items-center">
            
            <Col md={8} sm={12} className='mb-4'>
            <h2 className="fw-700 display-8 my-4"style={{ fontWeight: "500"}}>The Roaring Story Behind My Logo</h2>

                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                Curious about my lion logo? It’s no accident! The three hexagons forming my logo represent  <b style={{ fontSize:"20px", fontWeight: "900"}}> the pillars of my work Research, Tech and Design.</b> Together, they form a balanced and structured foundation, just like the backbone of any great design. It’s all about creating something bold, strong, and impactful. My work is about bringing these elements together to create something fierce!
                </p>

                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                Why yellow? Well, yellow is my happy color! It radiates positivity, energy, and creativity, all the things I bring to my work. It’s the color that makes people smile, that brightens a space, and for me, it <b style={{ fontSize:"20px", fontWeight: "900"}}>reflects my enthusiasm for design and innovation.</b>  It’s about sparking joy, confidence, and warmth in everything I do.
                </p>
                <p className="mb-4" style={{fontWeight: "500", textAlign: "justify", lineHeight: "1.5em", fontSize: "18px"}}>
                Oh, and the lion part? That’s a nod to my childhood nickname! With my wild, frizzy curls, I was called a baby lion growing up, and it just stuck. Now, <b style={{ fontSize:"20px", fontWeight: "900"}}>the lion represents my bold, confident approach to life.</b> Whether it's taking on new challenges or pushing creative boundaries, I bring that fearless attitude to everything I do.
                </p>
            
            </Col>
            <Col className="d-flex align-items-stretch" md={4} sm={12}>
            <Image 
    src={logo} 
    alt="Voleta-Logo" 
    className="img-fluid w-100 object-fit-cover" 
    style={{
        height: "20%",   
    }} 
/>
            </Col>
        </Row>

        <Row className="justify-content-around my-2 flex-column">
            <Col className=''>
            <h2 className="fw-700 display-8 my-4"style={{ fontWeight: "500"}}>Building my UX Arsenal with</h2>
            </Col>
            <Col>
            <div className="d-flex flex-wrap align-items-top justify-content-center mt-3">
                <Skills icon={<FaSearch />} skill="User Research" />
                <Skills icon={<FaMagic />} skill="Interaction Design" />
                <Skills icon={<FaPencilAlt />} skill="Wireframing" /> 
                <Skills icon={<FaUserCheck />} skill="User Testing" />      
            </div>
            </Col>
            <Col>
            <div className="d-flex flex-wrap align-items-top justify-content-center mb-3">
                <Skills icon={<FaClock />} skill="Rapid Prototyping" />
                <Skills icon={<FaSitemap />} skill="Information Architecture" />
                <Skills icon={<FaCode />} skill="Web Development" />
                <Skills icon={<FaDatabase />} skill="MERN Stack" />
                
            </div>
            </Col>
            
        </Row>
        
    </Container>
  );
};

export default About;