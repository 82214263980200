import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import learnerPersona from '../assets/learner-persona.png';
import expertPersona from '../assets/teacher-persona.png';
import userFlow from '../assets/userflow.png';
import informationArchitecture from '../assets/IA.png';

const ResearchGallery = () => {
  const images = [
    { src: learnerPersona, alt: 'Learner Persona' },
    { src: expertPersona, alt: 'Expert Persona' },
    { src: userFlow, alt: 'User Flow' },
    { src: informationArchitecture, alt: 'Information Architecture' },
  ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Track if the image is loading

  const handleImageClick = (index) => {
    setLoading(true); // Set loading state when clicking
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Preload all images when the component mounts
  useEffect(() => {
    images.forEach(image => {
      const img = new window.Image();
      img.src = image.src;
    });
  }, []);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once image is loaded
  };

  return (
    <>
      <Row className="gallery my-4">
        {images.map((image, index) => (
          <Col md={6} key={index} className="mb-4">
            <Image 
              src={image.src} 
              alt={image.alt} 
              fluid 
              onClick={() => handleImageClick(index)} 
              style={{ cursor: 'pointer', width: '100%' }} 
            />
          </Col>
        ))}
      </Row>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onImageLoad={handleImageLoad} // Track image loading
          onMovePrevRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + 1) % images.length);
          }}
        />
      )}

      {loading && isOpen && <div className="loader">Loading...</div>} {/* Optional loader display */}
    </>
  );
};

export default ResearchGallery;
