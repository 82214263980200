import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { FaLinkedinIn, FaBehance, FaExternalLinkAlt } from 'react-icons/fa';
import image from '../assets/voleta-logo-removebg-preview.png';
import '../css/common.css'
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Navigation = () => {
  return (
    <Navbar collapseOnSelect expand="md" className="sticky-top d-flex align-items-center" md={6} style={{backgroundColor:"#fbfcf8"}}>
      {/* Left Section */}
      <Navbar.Brand href="/">
        <div className="d-flex align-items-center">
          <img
          src={image}
          alt="Voleta Noronha"
          height="50"
          className="mr-2 my-auto"
          />
          <div className="align-middle">
            <span className="align-middle" style={{ fontSize: "25px", fontWeight:"700", color:"#313638" }}>
              VOLETA NORONHA
            </span>
          </div>
        </div>
      </Navbar.Brand>

      {/* Center Section */}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="ml-5 pl-5">
        <Nav className="mx-auto">
          <li className="nav-item">
            <NavLink as={Link} to="/" className="nav-link" exact style={{ fontSize: "18px", margin: "0 15px" }}>
              <span className="d-inline-block">Work</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink as={Link} to="/about" className="nav-link" exact style={{ fontSize: "18px", margin: "0 15px" }}>
              <span className="d-inline-block">About</span>
            </NavLink>
          </li>
          {/* Link to old contact page */}
          {/* <li className="nav-item">
            <NavLink as={Link} to="/contact" className="nav-link" exact style={{ fontSize: "18px", margin: "0 15px" }}>
              <span className="d-inline-block">Contact</span>
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink as={Link} to="mailto:work.voleta@gmail.com" className="nav-link" target="_blank" rel="noopener noreferrer" exact style={{ fontSize: "18px", margin: "0 15px" }}>
              <span className="d-inline-block">Contact</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink as={Link} to="https://drive.google.com/file/d/1edD8aTjx4I1ahEeXtG3jBcDtEvojeILd/view?usp=sharing" className="nav-link" target="_blank" rel="noopener noreferrer" exact style={{ fontSize: "18px", margin: "0 15px" }}>
              <span className="d-inline-block">Resume<FaExternalLinkAlt className="ml-2" style={{ fontSize: "15px"}}/></span>
            </NavLink>
          </li>
        </Nav>

      {/* Right Section */}
      <Nav className="ml-auto">
        <li className="nav-item">
          <NavLink className="social-links" target="_blank" rel="noopener noreferrer" style={{ fontSize: "20px", margin: "0 10px" }}>
            <a href="https://www.linkedin.com/in/voleta-noronha/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          </NavLink>
        </li>
      </Nav>
    </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
