import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import research from '../assets/research.jpg';
import persona from '../assets/user-persona.png';
import journyemap from '../assets/user-journey-map.png';

const Content = () => {
  const images = [
    { src: research, alt: 'Coding and analysing of interview data', description:"Analysis of our data and coding them based on the user journey" },
    { src: persona, alt: 'Riya, User persona', description:"Riya, our adventurous traveler user persona" },
    { src: journyemap, alt: "Riya's journey through booking a trip", description:"Riya's journey as she goes through booking a trip with her friends" },
  ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Track if the image is loading

  const handleImageClick = (index) => {
    setLoading(true); // Set loading state when clicking
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Preload all images when the component mounts
  useEffect(() => {
    images.forEach(image => {
      const img = new window.Image();
      img.src = image.src;
    });
  }, []);

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false once image is loaded
  };

  return (
    <>
      <Row className="gallery my-4 align-items-center justify-content-center">
        {images.map((image, index) => (
          <Col
            md={index === 0 ? 7 : 6}  // First image takes full width, others take half
            key={index}
            className="mb-4"
          >
            <Image
              src={image.src}
              alt={image.alt}
              fluid
              onClick={() => handleImageClick(index)}
              style={{ cursor: "pointer", width: "100%" }}
            />
            <p
              style={{
                color: "gray",
                textAlign: "center",
                fontWeight: "500",
                lineHeight: "1.6em",
                marginTop: "9px",
              }}
            >
              {image.description}
            </p>
          </Col>
        ))}
      </Row>
  
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onImageLoad={handleImageLoad} // Track image loading
          onMovePrevRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setLoading(true); // Set loading before moving
            setPhotoIndex((photoIndex + 1) % images.length);
          }}
        />
      )}
  
      {loading && isOpen && <div className="loader">Loading...</div>} {/* Optional loader display */}
    </>
  );
};  

export default Content;
