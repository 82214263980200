import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../../css/skill-swap.css';
import image from '../../assets/skill-swap-hero-image.png';
import ResearchGallery from '../../components/ResearchGallery';
import ContentRow from '../../components/ContentRow';
import Images from '../../components/Images';

const projectDetails = [
    { title: 'ROLE', content: 'UX Researcher and Tester, UI Designer' },
    { title: 'TOOLS USED', content: 'Figma, Figjam, MS Teams, Excel' },
    { title: 'SKILLS', content: 'User Interview, Usability Test, Visual and Interaction Design' },
    { title: 'CLIENT', content: 'Academic Project' },
    { title: 'TIMELINE', content: 'May 2024 - Aug 2024' },
    { title: 'TEAM MEMBERS', content: '3' }
  ];
  
  const ProjectInfo = () => (
    <>
      <Row className="pb-4">
        {projectDetails.slice(0, 3).map((detail, index) => (
          <Col key={index}>
            <p className="my-0" style={{ color: "gray", fontWeight: "400" }}>{detail.title}</p>
            <p style={{ fontSize: "20px", lineHeight: "1.6em" }}>{detail.content}</p>
          </Col>
        ))}
      </Row>
      <Row>
        {projectDetails.slice(3).map((detail, index) => (
          <Col key={index}>
            <p className="my-0" style={{ color: "gray", fontWeight: "400" }}>{detail.title}</p>
            <p style={{ fontSize: "20px", lineHeight: "1.6em" }}>{detail.content}</p>
          </Col>
        ))}
      </Row>
    </>
  );

const notes = [
    { text: 'Cost-effective learning courses' },
    { text: 'Flexible schedule with minimal time commitment' },
    { text: 'Personalized learning' },
    { text: 'Friendly and certified experts to teach me the skill' },
    { text: 'Require hands-on training with my teacher' },
    { text: 'No access to equipment/tools required to learn' },
    { text: 'Difficult to network within my community' },
    { text: 'Flexible schedules to teach' },
    { text: 'Connect with new people and build a community' },
    { text: 'Additional source of income' },
    { text: 'Difficult to find students and advertise my skills' },
    { text: 'Refine skills and learn new skills' }
];

const NotesGrid = () => (
    <Row className="align-items-center justify-content-center mx-0 py-2">
      {notes.map((note, index) => (
        <Col key={index}  xs={12} sm={6} md={3} lg={2} className="stickynote mb-3">
          {note.text}
        </Col>
      ))}
    </Row>
  );

  const FigmaPrototype = () => {
    return (
      <Col>
        <iframe
            style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
            width="100%"
            height="450"
            src="https://embed.figma.com/proto/s7IpsnxbAczuVjZP5VJ8td/UXD5310-Capstone-Project-4?node-id=886-1185&node-type=frame&scaling=scale-down&content-scaling=fixed&page-id=318%3A759&starting-point-node-id=886%3A1185&show-proto-sidebar=1&embed-host=share"
            allowFullScreen
            title="Figma Prototype"
        ></iframe>
      </Col>
    );
  };

const SkillSwap = () => { 
    return (
    <Container fluid className="mx-0 px-0">
      <div className="px-3 backgroundcolor">
        <Row className="align-items-center justify-content-center" style={{ minHeight: "90vh"}}>
          <Col md={6} className="text-center">
            <h1 className="responsive-heading-case-study" style={{ fontWeight: "900"}}>
              Skill Swap: Bridging Learning and Earning
            </h1>
            
            <p style={{ color: 'gray', fontWeight: "500", textAlign: "center", paddingTop:"0.5em"}}>
              A fun, dynamic platform connecting learners with experts, where skills are swapped, knowledge is shared, and everyone grows, without breaking the bank.
            </p>
            {/* <p style={{ color: 'gray', fontWeight: "300"}}>
              5 min read
            </p> */}
            <Image src={image} className="img-fluid w-100 object-fit-cover"  alt="Skill Swap Header" />
          </Col >
        </Row>
      </div>
      <Row className="align-items-left justify-content-center mx-0 my-5">
        <Col md={6} className='my-5'>
          <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
            <b style={{fontWeight: "800"}}>Ever found yourself wanting to pick up a new skill just for fun, but you’re not sure who can teach you or how much it’ll cost? That’s exactly where Skill Swap steps in! </b>We’ve built a platform that bridges the gap between curious learners and skilled experts, offering personalized, affordable learning sessions. Learners can explore new skills without breaking the bank, paying a small fee to try things out before deciding to dive deeper. Meanwhile, experts get to share their passion and earn extra income. It’s a win-win that fosters growth, learning, and meaningful connections for everyone!
          </p>
          <p style={{ color:"gray", textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
            This case study walks you through our design process and thinking, turning learner and expert needs into a seamless platform that brought Skill Swap to life!
          </p>
        </Col>
      </Row>
      <Row className="align-items-flex-start justify-content-center mx-0 my-5 backgroundcolor">
        <Col md={9} className='my-5'>
          <h2 className="pb-4" style={{color:"gray"}}>
            Quick Facts
          </h2>
          <ProjectInfo />
        </Col>
      </Row>
      <Row  className="align-items-center justify-content-center mx-0 py-5" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title'>
            THE PROBLEMS
          </p>
          <h2 className="py-2 section-heading">
            Learning shouldn't break the bank!
          </h2>
          <Row className="align-items-start justify-content-center mx-0 py-3 mb-2" style={{textAlign:"justify"}}>
            <Col style={{textAlign:"center"}}>
              <Row md={4} className="flex-column align-items-center justify-content-center mx-0">
                <p className="my-0 py-0" style={{ fontSize:"4em",fontWeight: "900", color:"#007BA7"}}>$645B</p>
                <p style={{ color: 'gray', fontWeight: "500"}}>E-Learning Market by 2030</p>
              </Row>
              <Row md={4} className="flex-column align-items-center justify-content-center mx-0">
                <p className="my-0 py-0" style={{  fontSize:"4em",fontWeight: "900", color:"#007BA7"}}>$455B</p>
                <p style={{ color: 'gray', fontWeight: "500"}}>Gig Economy Growth</p>
              </Row>
            </Col> 
            <Col md={8} style={{lineHeight: "1.6em", paddingTop:"1em", paddingBottom:"1em", fontSize:"20px"}}>
              <Row className='pb-3'>
                Our secondary research proved to us that the world is hungry for affordable, personalized learning options. With the e-learning market expected to hit $645 billion by 2030, there’s a huge demand for accessible education. At the same time, the gig economy is growing, with more people looking for side hustles. It’s set to reach $455 billion by 2025! Skill Swap fits right in by connecting learners with experts for flexible, cost-effective learning.
              </Row>
              <Row>
                <b style={{fontWeight: "800"}}>What do we bring to the table? </b>
                Unlike platforms like Skill Share or Meetup, Skill Swap focuses on real-time, personalized sessions, giving experts a chance to earn from their hobbies without needing expensive equipment.
              </Row>
            </Col>                   
          </Row>  
          <h2 className="py-4 mb-4" style={{color:"gray", fontSize:"2rem", fontWeight: "900"}}>
            “I want to try it out first before committing fully, just like a test drive but ...for learning.”
          </h2>
          <p style={{ fontSize:"20px",  lineHeight: "1.6em"}}>Here are some of the insights from the interviews we conducted for learners and experts,</p>
          <NotesGrid />
        </Col>
      </Row>  
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4 backgroundcolor" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title' >
            OUR SOLUTION
          </p>
          <h2 className="py-2 section-heading" >
            Swap Skills, Share Knowledge
          </h2>
          <Row className="justify-content-between mx-0 py-5 mb-2" style={{textAlign:"justify"}}>
            <Col md={5} style={{fontSize:"20px",  lineHeight: "1.6em", paddingBottom:"1em", }}>
              <Row className='pb-3'>
                We created Skill Swap, a platform that bridges the gap between learners and experts. With this platform, learners can gain practical skills from experts without high costs, and experts can monetize their knowledge by teaching what they love. It’s a win-win situation, where both sides grow, learn, and create new opportunities.
              </Row>
              <Row className='my-4' style={{ color:"gray", fontWeight: "400", }}>
                TRY OUT THE PROTOTYPE
              </Row>
            </Col>
            <Col md={7} ><FigmaPrototype /></Col>
          </Row>
        </Col>
      </Row> 
      <Row  className="align-items-center justify-content-center mx-0 pt-5" style={{textAlign:"center"}}>
        <Col md={10} className='py-2'>
          <p className='my-0 section-title'>
            THE DESIGN JOURNEY
          </p>
          <h2 className="py-2 section-heading">
            From Idea to Impact
          </h2>
          <Row className="align-items-left justify-content-center mx-0 py-3">
            <Col md={10}>
              <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
                <b style={{fontWeight: "800"}}>Who’s at the heart of this design? Our Users,  </b>Jake, a curious learners eager to dive into new skills and Alex, a passionate expert ready to share their knowledge! Every interaction was designed with our users in mind, making sure it is a fuss-free process. 
              </p>
              <p style={{ color:"gray", textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
              Before jumping into wireframing, we synthesized research data to define the user flow, and streamline information architecture. Below are the snapshots of the same.
              </p>
            </Col>
            <ResearchGallery />
          </Row>
          <h2 className="py-4 mb-4" style={{color:"gray", fontSize:"2rem", fontWeight: "900"}}>
            Design, Test, Repeat... and then add some color
          </h2>
          <Images />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4 backgroundcolor" style={{textAlign:"center"}}>
        <Col md={8} className='py-2'>
          <p className='my-0 section-title' >
            FINAL STEP
          </p>
          <h2 className="py-2 section-heading" >
            Adding Interactivity to Our Work
          </h2>
          <Row className="align-items-left justify-content-center mx-0 py-3">
            <Col md={12}>
              <p style={{ textAlign:"justify", fontSize:"20px", fontWeight: "500", lineHeight: "1.6em"}}>
                After adding life to our black and white wireframes, we finally began prototyping. We saved this step for last, knowing how caught up we can get perfecting every interaction. Throughout the process, we stayed focused on our user personas and testing feedback, ensuring that every interaction aligned with their needs and expectations.
              </p>
            </Col>
          </Row>
          <ContentRow />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mx-0 py-5 my-4" style={{textAlign:"center"}}>
        <Col md={6} className='py-2'>
          <p className='my-0 section-title' >
            RETROSPECTION
          </p>
          <h2 className="py-2 section-heading" >
            Lessons Learned & Next Steps
          </h2>
          <Row className="mx-0 pt-5 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>RESEARCH MATTERS, BUT TIME IS JUST AS CRUCIAL</p>
              <p style={{fontSize:"20px"}}>We faced challenges with research since this was a new concept. It took a few tough weeks to understand and balance both user and business needs. Once we found that balance, we could start the project. This experience taught us that research is crucial in design, but it’s important to begin with existing information to save time.</p>
            </Col>
          </Row>
          <Row className="mx-0 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>FAIL FAST, LEARN FASTER</p>
              <p style={{fontSize:"20px"}}>One area we can improve is getting designs out quickly. It’s better to fail fast and refine rather than perfect every screen. Setting goals based on timelines is also key; we learned to avoid scope creep and focus on realistic goals.</p>
            </Col>
          </Row>
          <Row className="mx-0 mb-4" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>TEAMWORK MAKES THE DREAM WORK</p>
              <p style={{fontSize:"20px"}}>One of the main challenges we faced was the time-bound nature of the project, especially when it came to scheduling our weekly status update meetings. Coordinating everyone’s availability was a concern, but the team made sure to respect each other's time and commitments. By working together, alinging our schedules and communicating openly, we were able to leverage each of our strengths and improve collaboration.</p>
            </Col>
          </Row>
          <Row className="mx-0" style={{textAlign:"justify"}}>
            <Col>
              <p style={{fontSize:"24px", color:"gray"}}>NEXT STEPS</p>
              <p  style={{fontSize:"20px"}}>In our next steps we want to complete the user flow for the instructor. We want to conduct another round of usability tests for our users test alternative error states and flows possible. We intend to further the app feature by creating a complete communication channel too.</p>
            </Col>
          </Row>
        </Col>
      </Row> 
      <Row className="align-items-center justify-content-center mx-0 py-5 my-2" style={{textAlign:"center"}}>
        <Col md={6}>
          <p style={{ color:"gray", fontSize:"42px", fontWeight: "600", lineHeight: "1.6em"}}>Thank you for Reading!</p>
          <p style={{  fontSize:"20px", lineHeight: "1.6em"}}>I’d love to connect and hear your thoughts! Feel free to reach out to me via email at <a href='mailto:work.voleta@gmail.com'>work.voleta@gmail.com.</a> You can also check out the full case study on <a href="https://www.behance.net/gallery/205389405/SkillSwap-Case-Study" target="_blank" rel="noopener noreferrer">Behance</a> for a more in-depth look.</p>
        </Col>  
      </Row>
    </Container>
  );
};

export default SkillSwap;